<template>
  <div>
    <form id="formCreateSoal" class="form pb-2" @submit.prevent="submitData">
      <label style="min-width: 80px;">Tipe Sekolah</label>
      <div class="input-group mb-2">
        <el-select
          v-model="selects.multiple"
          class="w-100"
          multiple
          placeholder="Multiple Select"
        >
          <el-option
            v-for="option in typeSchoolGetters"
            :key="option.label"
            :label="option.label"
            :value="option.value"
          >
          </el-option>
        </el-select>
      </div>
      <button
        class="btn btn-primary pl-2 pr-2 float-right btn-sm"
        type="submit"
      >
        Simpan Data
      </button>
    </form>
  </div>
</template>
<script>
import broker from "../../../../../utils/broker";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import {preLoadingSedangMemproses} from "@/services/internalGlobalService";
// import { _ } from "vue-underscore";

export default {
  name: "DetailGenerate",
  props: ["onClose", "fetchData"],
  computed: {
    ...mapGetters("generateToken", ["typeSchoolGetters"])
  },
  data() {
    return {
      data: [],
      selects: {
        province: [
          { value: "Gov SMA", label: "SMA" },
          { value: "Gov SMK", label: "SMK" },
          { value: "Gov SLB", label: "SLB" }
        ],
        city: [
          { value: "Gov SD", label: "SD" },
          { value: "Gov SMP", label: "SMP" },
          { value: "Gov SMA", label: "SMA" },
          { value: "Gov SMK", label: "SMK" },
          { value: "Gov SLB", label: "SLB" }
        ],
        multiple: "ARS"
      }
    };
  },
  mounted() {
  },
  methods: {
    async submitData() {
      try {
        let self = this;
        self.loading = true;
        const formData = {
          school_types: this.selects.multiple
        };
        preLoadingSedangMemproses();
        await broker.fetch.post(`/monitoring-token`, formData);
        Swal.close();
        this.onClose();
        this.fetchData();
      } catch (error) {
        let message = "Opps ! terdapat kesalahan.";
        if (error.response) {
          if (error.response.data.errors) {
            this.onClose();
            message = this.handleErrorApi(error.response.data.errors, "string");
          }
          Swal.fire("Alert", message, "warning");
        }else{
          Swal.close();
        }
      } finally {
        this.loading = false;
      }
    }
    // loadData() {
    //   let self = this;
    //   broker.fetch
    //     .get(`/monitoring-token/school-type`)
    //     .then((res) => {
    //       if (res.data.data) {
    //         res.data.data.forEach((item) => {
    //           self.data.push(item);
    //         });
    //       }
    //       console.log(`---self.data`, self.data);
    //     })
    //     .catch((error) => {
    //       let message = "Opps! Ada kesalahan.";

    //       if (error.response) {
    //         if (error.response.data.errors) {
    //           message = self.handleErrorApi(
    //             error.response.data.errors,
    //             "string"
    //           );
    //         }

    //         self.$Swal.fire("Alert!", message, "warning");
    //       }
    //       self.$Progress.fail();
    //     });
    // },
  }
};
</script>
